export const regionLabelsMap = {
  region_interior: 'Interior',
  region_fraser: 'Fraser',
  region_vancouver_coastal: 'Vancouver Coastal',
  region_vancouver_island: 'Vancouver Island',
  region_northern: 'Northern',
  none: 'None',
};

export const psiRegionLabelsMap = {
  region_interior: 'Interior',
  region_fraser: 'Fraser',
  region_vancouver_coastal: 'Vancouver Coastal',
  region_vancouver_island: 'Vancouver Island',
  region_northern: 'Northern',
};
